






















































import { Porto } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { PortoService } from "@/core/services/geral";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaPorto extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Nome', value: 'nome' }
    ];

    item = new Porto();
    service = new PortoService();

    tipoUsuario = EnumTipoUsuario;

    mounted() {
        this.app.perfilId == this.tipoUsuario.Administrador ? this.headers.push({text: "Ativo", value: 'ativo', type:'boolean'}) : '';
    }

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, "Ternos.Terno").then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.loading = false));
    }

    AbrirDialogCadastro(item?: Porto){
        if(item){
            this.service.ObterPorId(item.id, "Ternos.Terno, LocaisOperacao.Periodos").then(
                res=>{
                    this.item = res.data;
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else{
            this.item = new Porto();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    Excluir(item: Porto){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }
}
